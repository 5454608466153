<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">Número de documento</td>
        <td class="left">{{ data['RUToRUTProvisionalDeContacto_c'] || data['DNIoPasaporte_c'] }}</td>
      </tr>
      <tr>
        <td class="left">E-mail</td>
        <td class="left">{{ data['PrimaryContactEmailAddress'] }}</td>
      </tr>
      <tr>
        <td class="left">Teléfono</td>
        <td class="left">  +{{ data['PrimaryPhoneCountryCode'] }} {{ data['PrimaryPhoneAreaCode'] }} {{ data['PrimaryPhoneNumber'] }}</td>
      </tr>
      <tr>
        <td class="left">Tipo de teléfono</td>
        <td class="left">{{ data['TipoDeTelefono_c'] }}</td>
      </tr>
      <tr>
        <td class="left">Formulario de origen</td>
        <td class="left">{{ data['FormularioDeOrigen_c'] }}</td>
      </tr>
      <tr>
        <td class="left">Institución</td>
        <td class="left">{{ data['Institucion_c'] }}</td>
      </tr>
      <tr>
        <td class="left">Fecha</td>
        <td class="left">{{ data['createdAt'] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = historial => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter(word => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>