<template>
  <table>
    <thead>
      <tr>
        <th>Dato</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="left">Nombre</td>
        <td class="left">{{ data['PrimaryContactPersonFirstName'] }}</td>
      </tr>
      <tr>
        <td class="left">Apellido Paterno</td>
        <td class="left">{{ data['PrimaryContactPersonLastName'] }}</td>
      </tr>
      <tr>
        <td class="left">Número de documento</td>
        <td class="left">{{ data['RUToRUTProvisionalDeContacto_c'] || data['DNIoPasaporte_c'] }}</td>
      </tr>
      <tr>
        <td class="left">Email</td>
        <td class="left">{{ data['PrimaryContactEmailAddress'] }}</td>
      </tr>
      <tr>
        <td class="left">Celular</td>
        <td class="left"> +{{ data['PrimaryPhoneCountryCode'] }} {{ data['PrimaryPhoneAreaCode'] }} {{ data['PrimaryPhoneNumber'] }}</td>
      </tr>
      <tr>
        <td class="left">Formulario de origen</td>
        <td class="left">{{ data['FormularioDeOrigen_c'] }}</td>
      </tr>
      <tr>
        <td class="left">Institución</td>
        <td class="left">{{ data['Institucion_c'] }}</td>
      </tr>
      <tr>
        <td class="left">Situación laboral</td>
        <td class="left">{{ data['situacion_laboral'] }}</td>
      </tr>
      <tr>
        <td class="left">Empresa</td>
        <td class="left">{{ data['empresa'] }}</td>
      </tr>
      <tr>
        <td class="left">Cargo</td>
        <td class="left">{{ data['cargo'] }}</td>
      </tr>
      <tr>
        <td class="left">Antigüedad</td>
        <td class="left">{{ data['antiguedad'] }}</td>
      </tr>
      <tr>
        <td class="left">Dirección de la empresa</td>
        <td class="left">{{ data['empresa_direccion'] }}</td>
      </tr>
      <tr>
        <td class="left">Región de la empresa</td>
        <td class="left">{{ data['empresa_region'] }}</td>
      </tr>
      <tr>
        <td class="left">Comuna de la empresa</td>
        <td class="left">{{ data['empresa_comuna'] }}</td>
      </tr>
      <tr>
        <td class="left">Teléfono empresa</td>
        <td class="left"> +{{ data['empresa_country_code'] }} {{ data['empresa_area_code'] }} {{ data['empresa_phone_number'] }}</td>
      </tr>
      <tr>
        <td class="left">E-mail empresa</td>
        <td class="left">{{ data['empresa_email'] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */

import { toRefs } from 'vue'
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props)
    const formatHistorial = historial => {
      let historialArray = historial.split(/['"\s",']+/)
      let historialSinCorchetes = historialArray.filter(word => word.length > 3)
      return historialSinCorchetes
    }
    return {
      data,
      formatHistorial,
    }
  },
}
</script>
